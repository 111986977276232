:root {
  --webkit-footer-gap: 80px;
}
html,
body {
  padding: 0;
  margin: 0;
}
.main {
  min-height: 100vh;
  display: flex;
}

.mainLeft {
  width: 50%;
  background: #f2f2f2;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.textOmega {
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.mainRight {
  display: flex;
  flex-direction: column;
}
.headLogin {
  margin-bottom: 50px;
}
.content {
}
.boxView,
.boxViewWifi {
  border-radius: 20px;
  padding: 30px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  gap: 80px;
}
.viewButton {
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-around;
}
.QRCode {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}
.text_mobile {
  display: none;
}
.text_mobile_wifi {
  display: none;
}
.text_pc {
  margin-bottom: 20px;
  font-weight: 500;
}
.logoMobile {
  display: none;
}
.boxLink {
  margin: 0 auto;
}
.footerSecurity {
  position: absolute;
  bottom: 0;
  right: 50px;
}

@media screen and (min-width: 900px) {
  .mainRight {
    margin: 50px;
    width: 50%;
  }
  .mobileBottom {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .logoMobile {
    display: block;
  }
  .logoApp,
  .headLogin,
  .QRCode {
    display: none;
  }
  .boxView {
    border: none;
  }
  .boxViewWifi {
    border: none;
    padding-top: 0;
  }
  .main,
  .boxLink {
    flex-direction: column;
    justify-content: center;
  }
  .boxLink {
    align-items: center;
  }
  .mainLeft {
    width: 100%;
    background: transparent;
  }
  .viewButton {
    flex-direction: row;
    width: 400px;
    height: auto;
  }
  .mainRight {
    margin: 20px;
  }
  .textOmega {
    color: #b1ce12;
  }
  .text_mobile {
    display: block;
    max-width: 380px;
    margin: 0 auto;
    text-align: center;
    color: #53565e;
  }
  .text_mobile_wifi {
    max-width: 270px;
    display: block;
    margin: 0 auto;
    text-align: center;
    color: #53565e;
  }
  .text_pc {
    display: none;
  }
  .footerSecurity {
    align-items: center;
    bottom: 0;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: #777777;
    font-weight: 600;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 425px) {
  .svgButton {
    width: 130px;
  }
  .viewButton {
    width: 280px;
  }
  .footerSecurity {
    left: auto;
  }
}

@media screen and (max-width: 375px) {
  .svgButton {
    width: 121px;
  }
  .boxView {
    padding: 10px;
  }
  .viewButton {
    width: 260px;
  }
  .footerSecurity {
    right: 0;
  }
}
/* /////////////////////////////////// */
.mainWifiGuest {
  min-height: 100vh;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}
.textXeneaWallet {
  font-size: 3rem;
}
.footerXenea {
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
.headerLogo {
  margin-bottom: 2rem;
  /* margin-top: 5rem; */
}
.text_pc_wifi {
  margin-bottom: 2rem;
}
.text_pc_wifi::first-line {
  font-weight: 600;
}
.centerScreen {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 900px) {
  .text_pc_wifi {
    display: none;
  }

  .text_mobile_wifi {
    margin-bottom: 2rem;
  }
}
