html,
body {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}
.body {
  background: #f2f2f2;
}
.main {
  max-width: 1150px;
  margin: 10px auto;
  padding: 20px;
}
@font-face {
  font-family: "ＭＳ 明朝";
  panose-1: 2 2 6 9 4 2 5 8 3 4;
}
@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: "\@ＭＳ 明朝";
  panose-1: 2 2 6 9 4 2 5 8 3 4;
}
:global(p) {
  margin: 0;
}
:global(.MsoNormal) {
  font-family: "Arial", sans-serif;
}

:global(h2) {
  margin-top: 0.25in;
  margin-right: 0in;
  margin-bottom: 6pt;
  margin-left: 0in;
  line-height: 115%;
  page-break-after: avoid;
  font-size: 16pt;
  font-family: "Arial", sans-serif;
  font-weight: normal;
}
:global(h3) {
  margin-top: 16pt;
  margin-right: 0in;
  margin-bottom: 4pt;
  margin-left: 0in;
  line-height: 115%;
  page-break-after: avoid;
  font-size: 14pt;
  font-family: "Arial", sans-serif;
  color: #434343;
  font-weight: normal;
}
/* :global(p.MsoNormal, li.MsoNormal, div.MsoNormal) {
  margin: 0in;
  margin-bottom: 0.0001pt;
  line-height: 115%;
  font-size: 11pt;
  font-family: "Arial", sans-serif;
} */
:global(span) {
  word-wrap: break-word;
}
:global(a) {
  word-wrap: break-word;
}
