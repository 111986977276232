.mainContent {
  display: flex;
}
.mainLeft {
  width: 50%;
  background: #f2f2f2;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.textOmega {
  color: white;
  font-size: 22px;
  font-weight: 700;
}
.footer-security {
  background-color: transparent;
  width: 100%;
  padding-left: 80px;
  padding-bottom: 20px;
  font-family: "Azo Sans", sans-serif;
  margin: 0;
  text-align: end;
}
.mainRight {
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 3rem;
  align-items: center;
  font-family: "Azo Sans", sans-serif;
  width: 50%;
  max-width: 800px;
  padding: 30px 60px;
}
.btnLoginGoogle,
.btnLoginApple {
  background-color: transparent;
  color: rgb(30, 33, 42);
  width: 100%;
  border: 1.5px solid rgb(204, 204, 204);
  border-color: rgb(204, 204, 204) !important;
  border-radius: 8px;
  color: rgb(30, 33, 42) !important;
  padding: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  height: 56px;
}
.btnLoginGoogle {
  background: transparent !important;
  font-weight: 500;
}
.btnLoginApple {
  background-color: black !important;
  color: white !important;
}
.divButton {
  display: flex;
  gap: 20px;
}
.Checkbox {
  padding: 20px 0 30px 0;
  font-size: 1rem;
  color: black;
  /* font-weight: 500; */
}

.create_acc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e5e5e5;
  cursor: pointer;
  min-height: 60px;
  margin-top: 30px;
}
.footerSecurity {
  position: absolute;
  bottom: 10px;
  right: 50px;
}
a {
  color: #b1ce12 !important;
}
button svg {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .footer-security {
    width: 100%;
    max-width: 500px;
    padding: 16px 0;
  }
}

@media screen and (max-width: 900px) {
  .mainLeft {
    display: none;
  }
  .footer-security {
    text-align: center;
  }
  .mainRight {
    align-items: start;
    padding: 30px;
    width: 100%;
    max-width: 500px;
  }
  .divButton {
    display: flex;
    flex-direction: column;
  }
  .footerSecurity {
    align-items: center;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #777777;
    font-weight: 600;
    margin-bottom: 0;
  }
  .mainContent {
    justify-content: center;
  }
}

@media screen and (min-width: 1600px) {
  .mainRight {
    padding: 30px 60px;
  }

  .create-acc {
    margin-top: 40%;
  }
}
